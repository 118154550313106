import removeLayer from './removeLayer';
import removeSource from './removeSource';

const addLocationsLineLayer = (map, locations) => {
  removeLayer(map, 'LocationsLine');
  removeSource(map, 'LocationsLine');

  const coordinates = locations.map((location) => [
    location.longitude,
    location.latitude,
  ]);

  map.current.addSource('LocationsLine', {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: coordinates,
      },
    },
  });

  map.current.addLayer({
    id: 'LocationsLine',
    type: 'line',
    source: 'LocationsLine',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#FF0000',
      'line-width': 8,
      'line-opacity': 0.5,
    },
  });
};

export default addLocationsLineLayer;
