// React
import React, {useContext, useState} from 'react';

// MUI
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {Box, CircularProgress, IconButton} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// Hikers Social
import {AuthContext} from '../../../../context/auth-context';
import './HSDeviceField.scss';

const HSDeviceField = ({children}) => {
  const authContext = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newValue, setNewValue] = useState(authContext.documentValue.device);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const label = 'Device';
  const tooltipTitle = isEditing ? 'Cancel' : 'Edit Device';

  return (
    <Box className="hsDeviceField" display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
      <label htmlFor={label}>{label}:</label>

      {!isEditing && <p>{authContext.documentValue.device.charAt(0).toUpperCase() + authContext.documentValue.device.slice(1)}</p>}

      {isEditing &&
        <>
          <Select
            startAdornment={<SmartphoneIcon />}
            id="device"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            MenuProps={{disableScrollLock: true}}
          >
            <MenuItem value='garmin'>Garmin</MenuItem>
            <MenuItem value='spot'>Spot</MenuItem>
          </Select>

          {/* Show the save button */}
          {!isSaving &&
            <Tooltip title='Save' placement="top" TransitionComponent={Zoom} arrow disableInteractive>
              <IconButton
                aria-label="Save"
                onClick={async () => {
                  setIsSaving(true);
                  authContext.updateDevice(authContext.authValue.uid, newValue)
                      .then(() => {
                        authContext.onSnackbarClose();
                        authContext.onSnackbarOpen({message: 'Device update saved!', severity: 'success'});
                      })
                      .catch((error) => {
                        authContext.onSnackbarClose();
                        authContext.onSnackbarOpen({message: 'Device update failed!', severity: 'error'});
                        console.error(error);
                      })
                      .finally(() => {
                        setIsSaving(false);
                        setIsEditing(false);
                      });
                }}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          }

          {/* Show the progress indicator button */}
          {isSaving && <CircularProgress sx={{paddingLeft: '10px', paddingTop: '10px', paddingRight: '10px', paddingBottom: '10px'}} />}
        </>
      }

      <div>
        <Tooltip title={tooltipTitle} placement="top" TransitionComponent={Zoom} arrow disableInteractive
          open={tooltipIsOpen}
          onOpen={() => setTooltipIsOpen(true)}
          onClose={() => setTooltipIsOpen(false)}
          onMouseOver={() => setTooltipIsOpen(true)}
          onMouseOut={() => setTooltipIsOpen(false)}
        >
          <span>
            <IconButton
              aria-label={isEditing ? 'Close' : 'Edit'}
              onClick={() => {
                setIsEditing((prevState) => !prevState);
                setTooltipIsOpen((prevState) => !prevState);
              }}
            >
              {isEditing ? <CloseIcon /> : <EditIcon />}
            </IconButton>
          </span>
        </Tooltip>

        {!isEditing && children}
      </div>
    </Box>
  );
};

export default HSDeviceField;
