// React
import React, {Suspense, useContext, useEffect, useState} from 'react';
import {Await, useAsyncValue, useLoaderData, useParams} from 'react-router-dom';

// Material UI
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Hikers Social
import {AuthContext} from '../../context/auth-context';
import {MapContext} from '../../context/map-context';
import {userDocumentIdType} from '../../utils/userDocumentIdType';
import {incrementLoads} from '.././../firebase/functions';
import Dialog from './components/Dialog/Dialog';
import Map from './components/Map/Map';
import Snackbar from './components/Snackbar/Snackbar';
import HSSpeedDial from './components/HSSpeedDial/HSSpeedDial';
import './Map.css';

const Content = () => {
  const authContext = useContext(AuthContext);
  const resolvedDoc = useAsyncValue();
  const {username} = useParams();
  const [incremented, setIncremented] = useState(false);

  // If the user is not authenticated, and it's an actual map, hide the navigation.
  useEffect(() => {
    if (!authContext.authValue && resolvedDoc !== userDocumentIdType.INVALID) {
      authContext.setHideNavigation(true);
    } else {
      authContext.setHideNavigation(false);
    }

    return () => {
      authContext.setHideNavigation(false);
    };
  }, [authContext.authValue]);

  // If User is Free and has less than 50 loads.
  // If User is Pro and has less than 1000 loads.
  // If Viewer is a Pro user.
  useEffect(() => {
    if (incremented === false) {
      if (resolvedDoc.loads < 50 || (resolvedDoc.loads < 1000 && resolvedDoc.Pro === true) || (authContext.documentValue && authContext.documentValue.Pro === true)) {
        setIncremented(true);
        incrementLoads(username);
      }
    }
  }, [authContext.documentValue]);

  if (resolvedDoc === userDocumentIdType.INVALID) {
    return (
      <Container className="flexCenter">
        <Typography variant="h1" textAlign={'center'}>
              This map does not exist
        </Typography>
        <Typography variant="body1" textAlign={'center'}>
              Please check the URL
        </Typography>
      </Container>
    );
  }

  // If User is Free and has less than 50 loads.
  // If User is Pro and has less than 1000 loads.
  // If Viewer is a Pro user.
  if (resolvedDoc.loads < 50 || (resolvedDoc.loads < 1000 && resolvedDoc.Pro === true) || (authContext.documentValue && authContext.documentValue.Pro === true)) {
    return (
      <div className="map">
        <Snackbar />
        <Dialog />
        <HSSpeedDial />
        <Map />
      </div>
    );
  }

  return (
    <Container className="flexCenter">
      <Typography variant="h1" textAlign={'center'}>
          This map has reached its load limit!
      </Typography>
      <Typography variant="body1" textAlign={'center'}>Upgrade to Pro to view this map! Owner of this map can also upgrade to Pro to increase the load limit!</Typography>
    </Container>
  );
};

const MapPage = () => {
  const {uid, doc} = useLoaderData();
  const mapContext = useContext(MapContext);

  useEffect(() => {
    mapContext.setUserDocumentId(uid);
  }, []);

  return (
    <Suspense fallback={
      <Container className="flexCenter">
        <CircularProgress />
      </Container>
    }>
      <Await resolve={doc} >
        <Content />
      </Await>
    </Suspense>
  );
};

export default MapPage;
