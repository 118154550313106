// Firebase
import {getFunctions, httpsCallable} from 'firebase/functions';

// Hikers Social
import {app} from './app';
import {getDocumentIdByUsername} from './firestore';
import {userDocumentIdType} from '../utils/userDocumentIdType';

const functions = getFunctions(app, 'us-west1');

const createPortalLink = async () => {
  const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
  return functionRef({returnUrl: location.protocol + '//' + location.host + '/account'});
};

const incrementLoads = async (username) => {
  const documentId = await getDocumentIdByUsername(username);
  if (documentId == userDocumentIdType.INVALID) {
    return;
  }
  const functionRef = httpsCallable(functions, 'incrementLoads');
  return functionRef({docId: documentId});
};

export {
  createPortalLink,
  incrementLoads,
};
