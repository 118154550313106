// React
import React, {useContext, useState} from 'react';

// MUI
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {Box, CircularProgress, Hidden, IconButton, TextField} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

// Hikers Social
import {AuthContext} from '../../../../context/auth-context';
import './VanityURLField.scss';

const VanityURLField = ({children}) => {
  const authContext = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newValue, setNewValue] = useState(authContext.documentValue.username);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const label = 'Vanity URL';
  const prepend = 'hikers.social/map/';
  const tooltipTitle = authContext.documentValue.Pro ? isEditing ? 'Cancel' : 'Edit Vanity URL' : 'Upgrade to Pro to edit';

  return (
    <Box className="vanityURLField" display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
      <label htmlFor={label}>{label}:</label>

      {!isEditing && <p><Hidden smDown>{prepend}</Hidden>{authContext.documentValue.username}</p>}

      {isEditing &&
        <>
          <span style={{paddingLeft: '14px'}}>{prepend}</span>
          <TextField
            id={label}
            variant="outlined"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)} />

          {/* Show the save button */}
          {!isSaving &&
          <Tooltip title='Save' placement="top" TransitionComponent={Zoom} arrow disableInteractive>
            <IconButton
              aria-label="Save"
              onClick={async () => {
                setIsSaving(true);
                authContext.updateUsername(authContext.authValue.uid, newValue)
                    .then(() => {
                      authContext.onSnackbarClose();
                      authContext.onSnackbarOpen({message: 'Vanity URL update saved!', severity: 'success'});
                    })
                    .catch((error) => {
                      authContext.onSnackbarClose();
                      authContext.onSnackbarOpen({message: 'Vanity URL update failed!', severity: 'error'});
                      console.error(error);
                    })
                    .finally(() => {
                      setIsSaving(false);
                      setIsEditing(false);
                    });
              }}
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
          }

          {/* Show the progress indicator button */}
          {isSaving && <CircularProgress sx={{paddingLeft: '10px', paddingTop: '10px', paddingRight: '10px', paddingBottom: '10px'}} />}
        </>
      }

      <div>
        <Tooltip title={tooltipTitle} placement="top" TransitionComponent={Zoom} arrow disableInteractive
          open={tooltipIsOpen}
          onOpen={() => setTooltipIsOpen(true)}
          onClose={() => setTooltipIsOpen(false)}
          onMouseOver={() => setTooltipIsOpen(true)}
          onMouseOut={() => setTooltipIsOpen(false)}
        >
          <span>
            <IconButton
              aria-label={isEditing ? 'Close' : 'Edit'}
              disabled={!authContext.documentValue.Pro}
              onClick={() => {
                setIsEditing((prevState) => !prevState);
                setTooltipIsOpen((prevState) => !prevState);
              }}
            >
              {isEditing ? <CloseIcon /> : <EditIcon />}
            </IconButton>
          </span>
        </Tooltip>

        {!isEditing && children}
      </div>
    </Box>
  );
};

export default VanityURLField;
