const config = {
  apiKey: 'AIzaSyDuHPDUunOsB3EUqSES4LavoBDKxAOTsCE',
  authDomain: 'hikers.social',
  projectId: 'hiking-map-346514',
  storageBucket: 'hiking-map-346514.appspot.com',
  messagingSenderId: '874694189724',
  appId: '1:874694189724:web:96eee387005bbe255cc359',
  measurementId: 'G-X0PRBF8RPW',
};

export {
  config,
};

