// React
import {Container} from '@mui/system';
import React from 'react';

// Material
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import {Card, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';

// Hikers Social
import './ContentBlock.scss';

const ContentBlockComponent = ({title, content, imagePlacement, image, table, ...other}) => {
  const renderTableData = (content) => {
    switch (content) {
      case true:
        return <CheckBoxIcon color="success" />;
      case false:
        return <CloseIcon color="error"/>;
      default:
        return content;
    }
  };

  return (
    <div className='contentBlock' {...other}>
      <Container>

        <Grid
          container
          spacing={4}
          alignItems='center'
          justifyContent={'center'}
          direction={{sm: imagePlacement === 'right' ? 'row-reverse': 'row'}} >
          {image && <Grid item xs={6} sm={4}><img src={image} alt="" /></Grid>}
          { table &&
          <Grid item xs={12} sm={8}>
            {title && <Typography textAlign="center" variant='h2' paddingBottom={1}>{title}</Typography>}
            <Paper style={{padding: 10}}>
              <Table rows={table?.rows} columns={table?.cols}>
                <TableHead>
                  <TableRow>
                    {table.cols?.map((col, i)=><TableCell key={i}>{col.headerName}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    table.rows?.map((row, i)=>
                      <TableRow key={i}>
                        {table.cols?.map((col, j)=><TableCell key={j}>
                          {
                            renderTableData(row[col.field])
                          }
                        </TableCell>)}
                      </TableRow>,
                    )
                  }
                </TableBody>
              </Table>
            </Paper>
          </Grid>}
          {content &&
            <Grid item xs={12} sm={8}>
              {title && <Typography textAlign="center" variant='h2' paddingBottom={1}>{title}</Typography>}
              <Card>
                {
                  content.paragraphs.map((paragraph, i) => {
                    return i == content.paragraphs.length - 1 ?
                      <React.Fragment key={i}>{paragraph}</React.Fragment> :
                      <React.Fragment key={i}>{paragraph}<br /><br /></React.Fragment>;
                  })
                }
              </Card>
            </Grid>}
        </Grid>
      </Container>
    </div>
  );
};

export default ContentBlockComponent;
