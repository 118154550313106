// Firebase
import {addDoc, collection, doc, documentId, getDocs, getFirestore, increment, limit, onSnapshot, query, setDoc, updateDoc, where} from 'firebase/firestore';

// Hikers Social
import {formatDate} from '../utils/formatDate';
import {userDocumentIdType} from '../utils/userDocumentIdType';
import {app} from './app';


const firestore = getFirestore(app);

const queryCollectionData = (userDocumentId, dateRange) => {
  if (dateRange[0] === null || dateRange[1] === null || dateRange[0] === undefined || dateRange[1] === undefined || userDocumentId === undefined) {
    return undefined;
  }

  let start;
  let end;

  if (new Date().getTimezoneOffset() < 0) {
    const modifiedDate = new Date(dateRange[0]);
    modifiedDate.setDate(dateRange[0].getDate() - 1);
    start = formatDate(modifiedDate);
    end = formatDate(dateRange[1]);
  } else if (new Date().getTimezoneOffset() > 0) {
    const modifiedDate = new Date(dateRange[1]);
    modifiedDate.setDate(dateRange[1].getDate() + 1);
    start = formatDate(dateRange[0]);
    end = formatDate(modifiedDate);
  } else {
    start = formatDate(dateRange[0]);
    end = formatDate(dateRange[1]);
  }

  return query(
      collection(firestore, 'users', userDocumentId, 'locations'),
      where(documentId(), '>=', start),
      where(documentId(), '<=', end),
  );
};

const getDocumentReferenceByDocId = (documentId) => {
  if (documentId == userDocumentIdType.INVALID || documentId == userDocumentIdType.DEFAULT) {
    return undefined;
  }
  return doc(firestore, 'users', documentId);
};

const getDocumentReferenceByAuth = (authValue) => {
  if (authValue) {
    return doc(firestore, 'users', authValue.uid);
  } else {
    return undefined;
  }
};

const getDocumentReferenceByUsername = async (username) => {
  const userQuery = query(collection(firestore, 'users'), where('username', '==', (username) ? username : ''), limit(1));
  const userQuerySnapshot = await getDocs(userQuery);
  return !userQuerySnapshot.empty ? userQuerySnapshot.docs[0].ref : userDocumentIdType.INVALID;
};

const getDocumentIdByUsername = async (username) => {
  const userQuery = query(collection(firestore, 'users'), where('username', '==', (username) ? username : ''), limit(1));
  const userQuerySnapshot = await getDocs(userQuery);
  return !userQuerySnapshot.empty ? userQuerySnapshot.docs[0].id : userDocumentIdType.INVALID;
};

const getDocumentByUsername = async (username) => {
  const userQuery = query(collection(firestore, 'users'), where('username', '==', (username) ? username : ''), limit(1));
  const userQuerySnapshot = await getDocs(userQuery);
  return !userQuerySnapshot.empty ? userQuerySnapshot.docs[0].data() : userDocumentIdType.INVALID;
};

const createDocument = (uid, data) => {
  return setDoc(doc(firestore, 'users', uid), data);
};

const updateUsername = async (uid, username) => {
  // TODO: Check if the returned document is the same as the one being updated. If it is, then don't throw an error.
  const userQuerySnapshot = await getDocs(query(collection(firestore, 'users'), where('username', '==', username), limit(1)));
  if (!userQuerySnapshot.empty) {
    throw new Error('Username already in use.');
  }

  return updateDoc(doc(firestore, 'users', uid), {'username': username});
};

const updateFeed = async (uid, feed) => {
  return updateDoc(doc(firestore, 'users', uid), {'feed': feed});
};

const updateDevice = async (uid, device) => {
  return updateDoc(doc(firestore, 'users', uid), {'device': device});
};

const buyPro = async (currentUser, API_ID, setLoadingHandler) => {
  const collectionReference = collection(firestore, 'customers', currentUser.uid, 'checkout_sessions');
  const documentReference = await addDoc(collectionReference, {
    price: API_ID,
    allow_promotion_codes: true,
    success_url: location.protocol + '//' + location.host + '/account',
    cancel_url: location.protocol + '//' + location.host + '/account',
  }).catch((error) => {
    return doc(collectionReference, error.message.split('/').at(-1));
  });

  return onSnapshot(documentReference, (snap) => {
    // Wait for the CheckoutSession to get attached by the extension
    const {error, url} = snap.data();

    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`);
    }
    if (url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(url);
      // window.open(url, '_blank').focus();
      setLoadingHandler(false);
    }
  });
};

const incrementLoads = async (docRef) => {
  return updateDoc(docRef, {
    loads: increment(1),
  });
};


export {
  buyPro,
  createDocument,
  incrementLoads,
  getDocumentByUsername,
  getDocumentIdByUsername,
  getDocumentReferenceByUsername,
  getDocumentReferenceByDocId,
  getDocumentReferenceByAuth,
  queryCollectionData,
  updateDevice,
  updateFeed,
  updateUsername,
};
