import padTo2Digits from './padTo2Digits';

const formatDate = (date) => {
  if (date === null || date === undefined) {
    return undefined;
  } else {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }
};

export {
  formatDate,
};
