import addImage from './addImage';
import imageType from '../imageType';
import mapImages from '../mapImages';
// import pulsingDot from '../../animations/pulsingDot';

const addImages = (map) => {
  addImage(map, mapImages.Default, imageType.ImageData, '../icons/mapbox-marker-icon-20px-red.png');
  addImage(map, mapImages.Message, imageType.ImageData, '../icons/mapbox-marker-icon-20px-red-message.png');
  // addImage(map, mapImages.Current, imageType.StyleImageInterface, pulsingDot(map));
};

export default addImages;
