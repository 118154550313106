// React
import React, {useContext, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
// Material
import {Box} from '@mui/system';
import LoginIcon from '@mui/icons-material/Login';
import CircularProgress from '@mui/material/CircularProgress';

// Hikers Social
import {Avatar, Button, IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import Mappy from '../../../../assets/images/_Mappy.png';
import {AuthContext} from '../../../../context/auth-context';
import './Navigation.scss';

const Navigation = () => {
  const authContext = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mapUrl = authContext.documentValue ? `/map/${authContext.documentValue.username}` : undefined;

  if (authContext.hideNavigation) {
    return <></>;
  }

  return (
    <header>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} >
        <NavLink to="/" aria-label="Return home"><img className="logo" src={Mappy} alt="Hiker social logo" /></NavLink>
        {
            (authContext.authLoading || authContext.documentLoading) ?
              <CircularProgress /> :
              (authContext.authValue && authContext.documentValue) ?
                <>
                  <Tooltip title="My account">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ml: 2}}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <Avatar sx={{width: 32, height: 32}}>{authContext.documentValue['name']?.charAt(0)}</Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    disableScrollLock={true}
                    id='account-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem component={NavLink} onClick={handleClose} to="/account">Account</MenuItem>
                    <MenuItem component={NavLink} onClick={handleClose} to={mapUrl}>My Map</MenuItem>
                    <MenuItem onClick={()=>{
                      handleClose();
                      authContext.signOut();
                    }}>Logout</MenuItem>
                  </Menu>
                </> :
                <Button component={Link} to="/account" variant="contained" color='orangeButton' startIcon={<LoginIcon />}>Login</Button>
        }
      </Box>
    </header>
  );
};

export default Navigation;
