// React
import React from 'react';

// Material UI
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import {Link} from '@mui/material';
import Divider from '@mui/material/Divider';
import {useTheme} from '@mui/material/styles';
import {Container} from '@mui/system';

// Hikers Social
import './Footer.scss';

const style = {
  width: '100%',
  maxWidth: 900,
  margin: 'auto',
  marginBottom: '2.5rem',
};

const Footer = () => {
  const theme = useTheme();

  return (
    <>
      <footer style={{background: 'linear-gradient(transparent, ' + theme.palette.footer + ')'}}>
        {/* background: linear-gradient(transparent 2%, rgba(96, 125, 139, .5)); */}

        <Divider sx={style}/>
        <Container>
          <p>Need help? Email <Link fontWeight="bold" href="email:josh@hikers.social">josh@hikers.social</Link></p>
          <nav>
            <ul className='socialLinks'>
              <li><Link href="https://www.instagram.com/hikerssocial/" aria-label='Visit Hikers Social Instagram'><InstagramIcon color="primary" title="Instagram" /></Link></li>
              <li><Link href="https://twitter.com/HikersSocial" aria-label='Visit Hikers Social Twitter'><TwitterIcon color="primary" title="Twitter"/></Link></li>
            </ul>
          </nav>
          <p>
            <Link style={{marginRight: '.4em'}} href="https://hikers.social/privacy_policy.txt" rel="noopener" target="_blank">Privacy Policy</Link>
            | Copyright © {new Date().getFullYear()} Hikers Social LLC</p>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
