import React from 'react';
import {Typography, Link} from '@mui/material';

import Boots from '../../assets/images/Boots.png';
import Device from '../../assets/images/Device.png';
import Earth from '../../assets/images/Earth.png';
import Tent from '../../assets/images/Tent.png';
import Tree from '../../assets/images/Tree.png';

const homeData = [
  {
    title: 'Social',
    content: {
      paragraphs: [
        <Typography variant='p1' key={0}>Share your own interactive 3D map with friends and family! They&apos;ll be able to see your messages and live locations in
          a fun and engaging way. Invite your friends to follow your adventure on social media!</Typography>,
        <Typography variant='p1' key={1}>Become a Pro user to customize your own sharable vanity URL, store unlimited locations, and have increased map loads!</Typography>,
      ],
    },
    imagePlacement: 'left',
    image: Earth,
  },
  {
    title: 'Devices',
    content: {
      paragraphs: [
        'Hikers Social supports satellite-based tracking devices from both Garmin and SPOT. Want to use something else? Let us know and we\'ll add support for it!',
        'Some devices even allow you to add text to your device\'s messages - we support that! Click or tap on any map marker to see timestamps and messages.',
      ],
    },
    imagePlacement: 'right',
    image: Device,
  },
  {
    title: 'How It Works',
    content: {
      paragraphs: [
        'It\'s simple! Create an account, add your device, and we\'ll start tracking your adventure! On your account page, select the device that you use, ' +
        'and let us know the URL of your public feed provided by Garmin/SPOT. We\'ll use this to keep your map constantly up-to-date for your friends and family ' +
        'following you back home. ' +
        'You\'ll also find a sharable map link on the account page to easily share with whoever you want!',
      ],
    },
    imagePlacement: 'left',
    image: Boots,
  },
  {
    title: 'Features',
    imagePlacement: 'right',
    image: Tree,
    table: {
      cols: [{
        field: 'feature',
        headerName: 'Features',
      },
      {
        field: 'member',
        headerName: 'Pro',
      },
      {
        field: 'free',
        headerName: 'Free',
      }],
      rows: [
        {
          feature: 'Map Loads',
          member: '1000 monthly',
          free: '50 monthly',
        },
        {
          feature: 'Data Sync',
          member: '5 minutes',
          free: '10 minutes',
        },
        {
          feature: 'Locations',
          member: 'Unlimited',
          free: '1000',
        },
        {
          feature: 'Vanity Url',
          member: true,
          free: false,
        },
        // {
        //   feature: 'Data Export',
        //   member: true,
        //   free: false,
        // },
        // {
        //   feature: 'Video Export',
        //   member: true,
        //   free: false,
        // },
      ],
    },
  },
  {
    title: 'Privacy',
    content: {
      paragraphs: [
        <Typography variant='p1' key={0}>
          Hikers Social allows users to log in with their existing accounts (Google, Facebook, etc.). Your data will only be used for authentication - nothing else - and
          it will never be shared with any other parties.
        </Typography>,
        <Typography variant='p1' key={1}>
          Check out our <Link href="https://hikers.social/privacy_policy.txt" rel="noopener" target="_blank">Privacy Policy</Link> to learn more.
        </Typography>,
      ],
    },
    imagePlacement: 'left',
    image: Tent,
  },
];

export default homeData;

