import imageType from '../imageType';

const addImage = (map, id, type, data) => {
  if (!map.current.hasImage(id)) {
    if (type === imageType.ImageData) {
      map.current.loadImage(
          data,
          (error, image) => {
            if (error) {
              throw error;
            }
            if (!map.current.hasImage(id)) {
              map.current.addImage(id, image);
            }
          },
      );
    }
    if (type === imageType.StyleImageInterface) {
      map.current.addImage(id, data, {pixelRatio: 2});
    }
  }
};

export default addImage;
