import mapStyles from '../mapStyles';
import getCurrentStyleName from './getCurrentStyleName';

const toggleMapLayer = (map) => {
  const name = getCurrentStyleName(map);
  if (name === mapStyles.Satellite.name) {
    map.current.setStyle(mapStyles.Standard.url, {diff: false});
  } else if (name === mapStyles.Standard.name) {
    map.current.setStyle(mapStyles.Satellite.url, {diff: false});
  }
};

export default toggleMapLayer;
