// React
import React, {createContext, useEffect, useState} from 'react';

// Material UI
import {useMediaQuery} from '@mui/material';

// Hikers Social
import {modeType} from '../utils/modeType';

const ModeContext = createContext({
  mode: undefined,
  toggleMode: () => {},
});

const ModeContextProvider = (props) => {
  const systemPreference = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(systemPreference);

  useEffect(() => {
    setMode(systemPreference == modeType.Dark ? modeType.Dark : modeType.Light);
  }, [systemPreference]);

  const toggleModeHandler = () => {
    setMode((prevState) => prevState == modeType.Light ? modeType.Dark : modeType.Light);
  };

  return (
    <ModeContext.Provider
      value={{
        mode: mode,
        toggleMode: toggleModeHandler,
      }}
    >
      {props.children}
    </ModeContext.Provider>
  );
};

export {
  ModeContext,
  ModeContextProvider,
};
