// React
import React, {useContext} from 'react';

// Material UI
import {Snackbar as MUISnackbar, Alert as MUIAlert} from '@mui/material';

// Hikers Social
import {MapContext} from '../../../../context/map-context';

const Snackbar = () => {
  const context = useContext(MapContext);

  return (
    <MUISnackbar
      anchorOrigin={{vertical: context.snackbarState.vertical, horizontal: context.snackbarState.horizontal}}
      open={context.snackbarState.open}
      onClose={context.onSnackbarClose}
      key={context.snackbarState.vertical + context.snackbarState.horizontal}
    >
      <MUIAlert onClose={context.onSnackbarClose} severity={context.snackbarState.severity} variant="filled" elevation={6}>
        {context.snackbarState.message}
      </MUIAlert>
    </MUISnackbar>
  );
};

export default Snackbar;
