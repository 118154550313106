// React
import React, {useContext} from 'react';

// Material UI
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import LayersIcon from '@mui/icons-material/Layers';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// Hikers Social
import eventType from '../../../../utils/eventType';
import flyToCurrentLocation from '../../../../utils/mapbox/flyToCurrentLocation';
import fitToCurrentBounds from '../../../../utils/mapbox/fitToCurrentBounds';
import mapStyles from '../../../../utils/mapStyles';
import toggleMapLayer from '../../../../utils/mapbox/toggleMapLayer';
import {MapContext} from '../../../../context/map-context';
import {userDocumentIdType} from '../../../../utils/userDocumentIdType';
import './HSSpeedDial.css';

const HSSpeedDial = () => {
  const context = useContext(MapContext);
  const locations = (context.collectionValues) ? context.collectionValues.flatMap((document) => document.locations) : [];
  const isInvalidUser = context.documentLoading === false && context.userDocumentId === userDocumentIdType.INVALID;

  return (
    <SpeedDial
      ariaLabel="More Options"
      icon={<SpeedDialIcon icon={<MoreVertIcon />} openIcon={<CloseIcon />} />}
      open={context.speedDialState.open}
      onClick={() => context.speedDialState.open ? context.onSpeedDialClose() : context.onSpeedDialOpen()}
    >
      <SpeedDialAction key='1'
        icon={<MyLocationIcon />}
        tooltipTitle={locations.length > 0 ? 'Most Recent Location' : null}
        disabled={locations.length > 0 ? false : true}
        onClick={() => flyToCurrentLocation(context.map, locations)}
      />
      <SpeedDialAction key='2'
        icon={<FitScreenIcon />}
        tooltipTitle={locations.length > 0 ? 'Fit To Locations' : null}
        disabled={locations.length > 0 ? false : true}
        onClick={() => fitToCurrentBounds(context.map, eventType.Manual, locations)}
      />
      <SpeedDialAction key='3'
        icon={<LayersIcon />}
        tooltipTitle={isInvalidUser ? null : context.mapStyle === mapStyles.Standard.name ? 'Satellite View' : 'Standard View' }
        disabled={isInvalidUser}
        onClick={() => toggleMapLayer(context.map)}
      />
      <SpeedDialAction key='4'
        icon={<DateRangeIcon />}
        tooltipTitle={isInvalidUser ? null : 'Date Range'}
        disabled={isInvalidUser}
        onClick={() => context.onDateRangeOpen()}
      />
    </SpeedDial>
  );
};

export default HSSpeedDial;


