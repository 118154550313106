// React
import React from 'react';
import {Outlet} from 'react-router-dom';
import Footer from './components/Footer/Footer';

// Material UI
import {useTheme} from '@mui/material/styles';


// Hikers Social
import Navigation from './components/Navigation/Navigation';
import './Navigation.scss';

const NavigationPage = () => {
  const theme = useTheme();

  return (
    <>
      <Navigation/>
      <div className={`content ${theme.palette.mode}`}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default NavigationPage;
