// React
import React, {useState, useCallback, useContext} from 'react';

// Material UI
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GoogleIcon from '@mui/icons-material/Google';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentIcon from '@mui/icons-material/Payment';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Container from '@mui/system/Container';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

// React Youtube
import YouTube from 'react-youtube';

// Hikers Social
import './Account.scss';
import {AuthContext} from '../../context/auth-context';
import FeedURLField from './components/FeedURLField/FeedURLField';
import VanityURLField from './components/VanityURLField/VanityURLField';
import LinearProgressWithLabel from './components/LinearProgressBar/LinearProgressBar';
import HSSnackbar from './components/HSSnackbar/HSSnackbar';
import HSDeviceField from './components/HSDeviceField/HSDeviceField';

const AccountPage = () => {
  const authContext = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const garminVideoID = 'SWYnbEUdlbc';
  const spotVideoID = '9TPRdkrQIJY';

  const copyButtonClickHandler = useCallback(() => {
    if (authContext.documentValue) {
      navigator.clipboard.writeText('https://hikers.social/map/' + authContext.documentValue.username);
      authContext.onSnackbarClose();
      authContext.onSnackbarOpen({message: 'Link copied to clipboard', severity: 'info'});
    }
  }, [authContext.documentValue]);


  const signInWithGoogleHandler = useCallback(() => {
    authContext.signInWithGoogle();
  }, []);

  // const signInWithFacebookHandler = useCallback(() => {
  //   authContext.signInWithFacebook();
  // }, []);

  // const signInWithTwitterHandler = useCallback(() => {
  //   authContext.signInWithTwitter();
  // }, []);

  const signOutHandler = useCallback(() => {
    authContext.signOut();
  }, []);

  const onStripeBuyHandler = useCallback(async () => {
    authContext.setStripeLoading(true);
    authContext.stripeBuy(authContext.authValue, 'price_1MqPhKC74umylRRWcw9yu4Jn', authContext.setStripeLoading);
  }, [authContext.authValue]);

  const onStripeManageHandler = useCallback(async () => {
    authContext.setStripeLoading(true);
    const {data} = await authContext.stripeManage();
    try {
      // window.open(data.url, '_blank').focus();
      window.location.assign(data.url);
    } catch (error) {
      authContext.onSnackbarClose();
      authContext.onSnackbarOpen({message: 'Encountered a problem. Try again.', severity: 'error'});
      console.error(error);
    }
    authContext.setStripeLoading(false);
  }, [authContext.authValue]);

  const style = {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // User is logging in or loading information.
  if (authContext.authLoading || authContext.documentLoading) {
    return (
      <Container className="flexCenter">
        <CircularProgress />
      </Container>
    );
  }

  // User is logged in and information loaded.
  if (authContext.authValue && authContext.documentValue) {
    return (
      <Container>
        <HSSnackbar />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
        >
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h1" paddingBottom={3}>
            Configure Hikers Social account for {authContext.documentValue.device == 'garmin' ? 'Garmin' : 'SPOT'} device.
            </Typography>
            <YouTube
              videoId={authContext.documentValue.device == 'garmin' ? garminVideoID : spotVideoID}
              opts={{
                height: '360',
                width: '640',
                playerVars: {
                  // https://developers.google.com/youtube/player_parameters
                  autoplay: 1,
                },
              }}
            />
          </Box>
        </Modal>

        <div className='accountPage'>
          <Stack spacing={3}>
            {authContext.documentValue.Pro ?
                <Box justifyContent="center" style={{display: 'flex'}} >
                  <LoadingButton
                    onClick={onStripeManageHandler}
                    color='orangeButton'
                    loading={authContext.stripeLoading}
                    loadingPosition="start"
                    startIcon={<PaymentIcon />}
                    variant="contained"
                  >
                    {authContext.stripeLoading ? 'Loading Stripe' : 'Manage Pro'}
                  </LoadingButton>
                </Box> :
                <Box justifyContent="center" style={{display: 'flex'}} >
                  <LoadingButton
                    onClick={onStripeBuyHandler}
                    color='orangeButton'
                    loading={authContext.stripeLoading}
                    loadingPosition="start"
                    startIcon={<PaymentIcon />}
                    variant="contained"
                  >
                    {authContext.stripeLoading ? 'Loading Stripe' : 'Buy Pro'}
                  </LoadingButton>
                </Box>
            }

            <LinearProgressWithLabel />

            <Divider>
              <Chip label="Pro" color='secondary' variant='outlined' />
            </Divider>

            <VanityURLField>
              <Tooltip title="Copy to clipboard" placement="top" TransitionComponent={Zoom} arrow disableInteractive>
                <IconButton aria-label="Copy to clipboard" onClick={copyButtonClickHandler}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </VanityURLField>

            <Divider>
              <Chip label="FREE" color='primary' variant='outlined' />
            </Divider>

            <HSDeviceField />

            <FeedURLField>
              <Tooltip title="Learn more" placement="top" TransitionComponent={Zoom} arrow disableInteractive>
                <IconButton aria-label="Learn more" onClick={handleOpen}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </FeedURLField>

            <Divider />

            <Box justifyContent="center" style={{display: 'flex'}} >
              <LoadingButton
                color='signOutButton'
                loading={authContext.signOutLoading}
                loadingPosition="start"
                startIcon={<LogoutIcon />}
                onClick={signOutHandler}
                variant="contained"
              >
                  Sign out
              </LoadingButton>
            </Box>

            {authContext.signOutError &&
                <Box justifyContent="center" style={{display: 'flex'}} >
                  <Alert severity="error">{authContext.signOutError.message}</Alert>
                </Box>
            }

          </Stack>
        </div>
      </Container>
    );
  }

  // User is not logged in.
  return (
    <Container className='signInButtonWrapper'>
      <Button className='signInButton signInButtonGoogle' onClick={signInWithGoogleHandler}>
        <GoogleIcon className='socialMediaLogo' />
        Sign in with Google
      </Button>
      {/*
      <Button className='signInButton signInButtonFacebook' onClick={signInWithFacebookHandler}>
        <FacebookIcon className='socialMediaLogo'/>
        Sign in with Facebook
      </Button>
      <Button className='signInButton signInButtonTwitter' disabled onClick={signInWithTwitterHandler}>
        <TwitterIcon className='socialMediaLogo'/>
        Sign in with Twitter
      </Button>
      */}
    </Container>
  );
};

export default AccountPage;
