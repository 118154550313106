// React
import React, {useContext} from 'react';

// Material UI
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// Hikers Social
import {AuthContext} from '../../../../context/auth-context';

const HSSnackbar = () => {
  const context = useContext(AuthContext);

  return (
    <Snackbar
      anchorOrigin={{vertical: context.snackbarState.vertical, horizontal: context.snackbarState.horizontal}}
      open={context.snackbarState.open}
      onClose={context.onSnackbarClose}
      key={context.snackbarState.vertical + context.snackbarState.horizontal}
    >
      <Alert onClose={context.onSnackbarClose} severity={context.snackbarState.severity} variant="filled" elevation={6}>
        {context.snackbarState.message}
      </Alert>
    </Snackbar>
  );
};

export default HSSnackbar;
