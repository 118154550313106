const setTerrain = (map) => {
  if (!map.current.getTerrain()) {
    if (!map.current.getSource('mapbox-dem')) {
      map.current.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14,
      });
    }
    map.current.setTerrain({source: 'mapbox-dem', exaggeration: 2});
  }
};

export default setTerrain;
