// React
import React, {createContext, useState, useCallback, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

// React Firebase Hooks
import {useCollectionData, useDocumentData} from 'react-firebase-hooks/firestore';

// Hikers Social
import {userDocumentIdType} from '../utils/userDocumentIdType';
import {queryCollectionData, getDocumentReferenceByDocId} from '../firebase/firestore';


const MapContext = createContext({
  collectionValues: undefined,
  collectionLoading: undefined,
  collectionError: undefined,
  collectionSnapshot: undefined,
  dateRangeState: undefined,
  documentValues: undefined,
  documentLoading: undefined,
  documentError: undefined,
  documentSnapshot: undefined,
  map: undefined,
  mapStyle: undefined,
  setUserDocumentId: () => {},
  snackbarState: undefined,
  speedDialState: undefined,
  userDocumentId: undefined,
  onDateRangeSearch: () => { },
  onDateRangeChange: () => { },
  onDateRangeClose: () => { },
  onDateRangeOpen: () => { },
  onMapLoad: () => { },
  onMapStyleChange: () => { },
  onSnackbarClose: () => { },
  onSnackbarOpen: () => { },
  onSpeedDialClose: () => { },
  onSpeedDialOpen: () => { },
});


const MapContextProvider = (props) => {
  const initialDateRangeState = useCallback((searchParams) => {
    if (searchParams) {
      const {start, end} = Object.fromEntries(searchParams.entries());

      if (start && end) {
        const startDate = new Date(start);
        startDate.setHours(0, 0, 0, 0);

        const endDate = new Date(end);
        endDate.setHours(0, 0, 0, 0);
        return {value: [startDate, endDate], search: [startDate, endDate], open: false};
      }
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return {value: [currentDate, currentDate], search: [undefined, undefined], open: true};
  }, []);

  const [searchParams] = useSearchParams();
  const [userDocumentId, setUserDocumentId] = useState(userDocumentIdType.DEFAULT);
  const [dateRangeState, setDateRangeState] = useState(initialDateRangeState(searchParams));
  const [snackbarState, setSnackbarState] = useState({open: false, vertical: 'bottom', horizontal: 'center', message: '', severity: 'success'});
  const [documentValues, documentLoading, documentError, documentSnapshot] = useDocumentData(getDocumentReferenceByDocId(userDocumentId));
  const [collectionValues, collectionLoading, collectionError, collectionSnapshot] = useCollectionData(queryCollectionData(userDocumentId, dateRangeState.search));
  const [speedDialState, setSpeedDialState] = useState({open: false});
  const [map, setMap] = useState(false);
  const [mapStyle, setMapStyle] = useState();

  const onDateRangeChangeHandler = (dateRange) => {
    setDateRangeState((prevState) => {
      return {...prevState, value: dateRange};
    });
  };

  const onDateRangeCloseHandler = () => {
    setDateRangeState((prevState) => {
      return {...prevState, open: false};
    });
  };

  const onDateRangeOpenHandler = () => {
    setDateRangeState((prevState) => {
      return {...prevState, open: true};
    });
  };

  const onDateRangeSearchHandler = () => {
    setDateRangeState((prevState) => {
      return {...prevState, search: prevState.value, open: false};
    });
  };

  const onMapLoadHandler = (map) => {
    setMap(map);
  };

  const onMapStyleChangeHandler = (mapStyle) => {
    setMapStyle(mapStyle);
  };

  const onSnackbarCloseHandler = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarState((prevState) => {
      return {...prevState, open: false};
    });
  };

  const onSnackbarOpenHandler = (additionalState) => {
    setSnackbarState((prevState) => {
      return {...prevState, ...additionalState, open: true};
    });
  };

  const onSpeedDialOpenHandler = () => {
    setSpeedDialState((prevState) => {
      return {...prevState, open: true};
    });
  };

  const onSpeedDialCloseHandler = () => {
    setSpeedDialState((prevState) => {
      return {...prevState, open: false};
    });
  };

  useEffect(() => {
    if (userDocumentId === userDocumentIdType.INVALID && documentLoading === false) {
      onDateRangeCloseHandler();
      onSnackbarOpenHandler({message: 'Invalid URL', severity: 'error'});
    }
  }, [documentLoading]);

  return (
    <MapContext.Provider
      value={{
        collectionValues: collectionValues,
        collectionLoading: collectionLoading,
        collectionError: collectionError,
        collectionSnapshot: collectionSnapshot,
        dateRangeState: dateRangeState,
        documentValues: documentValues,
        documentLoading: documentLoading,
        documentError: documentError,
        documentSnapshot: documentSnapshot,
        map: map,
        mapStyle: mapStyle,
        setUserDocumentId: setUserDocumentId,
        snackbarState: snackbarState,
        speedDialState: speedDialState,
        userDocumentId: userDocumentId,
        onDateRangeSearch: onDateRangeSearchHandler,
        onDateRangeChange: onDateRangeChangeHandler,
        onDateRangeClose: onDateRangeCloseHandler,
        onDateRangeOpen: onDateRangeOpenHandler,
        onMapLoad: onMapLoadHandler,
        onMapStyleChange: onMapStyleChangeHandler,
        onSnackbarClose: onSnackbarCloseHandler,
        onSnackbarOpen: onSnackbarOpenHandler,
        onSpeedDialClose: onSpeedDialCloseHandler,
        onSpeedDialOpen: onSpeedDialOpenHandler,
      }}
    >
      {props.children}
    </MapContext.Provider>
  );
};

export {
  MapContext,
  MapContextProvider,
};

