// React
import React from 'react';
import ContentBlockComponent from './ContentBlock/ContentBlock';
// Hikers Social
import HeroComponent from './Hero/Hero';

import './Home.scss';
import homeData from './homeContent';
import VideoComponent from './Video/video';

const HomePage = () => {
  return (
    <div className="home">
      <HeroComponent />
      <VideoComponent />
      { homeData && homeData.map((block, i)=>
        <ContentBlockComponent {...block} key={i} />,
      )}
    </div>
  );
};

export default HomePage;
