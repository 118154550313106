// React
import React from 'react';
// Material UI
import {useTheme} from '@mui/material/styles';
// Hikers Social
import video from '../../../assets/videos/video.webm';
import './video.scss';

const VideoComponent = () => {
  const theme = useTheme();

  return (
    <div className="video" style={{background: theme.palette.background.default}}>
      <video autoPlay={true} controls={false} loop={true} muted >
        <source src={video} type="video/webm"/>
      </video>
    </div>
  );
};

export default VideoComponent;
