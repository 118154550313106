const flyToCurrentLocation = (map, locations) => {
  const currentLocation = locations.at(-1);

  if (currentLocation === undefined) {
    // enqueueSnackbar("No locations. Change date range.", { variant: "warning", });
    return;
  }

  map.current.flyTo({
    center: [currentLocation.longitude, currentLocation.latitude],
    zoom: 16,
    pitch: 45,
    bearing: 0,
    essential: true,
  });
};

export default flyToCurrentLocation;
