// React
import React, {createContext, useEffect, useState} from 'react';

// React Firebase Hooks
import {useAuthState, useSignInWithFacebook, useSignInWithGoogle, useSignInWithTwitter, useSignOut} from 'react-firebase-hooks/auth';
import {useDocumentData} from 'react-firebase-hooks/firestore';

// Hikers Social
import {auth, getAdditionalUserInfo} from '../firebase/auth';
import {buyPro, createDocument, getDocumentReferenceByAuth, updateDevice, updateFeed, updateUsername} from '../firebase/firestore';
import {createPortalLink} from '../firebase/functions';

const AuthContext = createContext({
  auth: undefined,
  authValue: undefined,
  authLoading: undefined,
  authError: undefined,
  documentValue: undefined,
  documentLoading: undefined,
  documentError: undefined,
  documentSnapshot: undefined,
  hideNavigation: undefined,
  onSnackbarClose: () => { },
  onSnackbarOpen: () => { },
  setHideNavigation: () => { },
  setStripeLoading: () => { },
  setUpdatedDevice: () => {},
  setUpdatedEmail: () => {},
  setUpdatedFeed: () => {},
  setUpdatedName: () => {},
  setUpdatedUsername: () => { },
  signInWithFacebook: () => {},
  signInWithFacebookUser: undefined,
  signInWithFacebookLoading: undefined,
  signInWithFacebookError: undefined,
  signInWithGoogle: () => {},
  signInWithGoogleUser: undefined,
  signInWithGoogleLoading: undefined,
  signInWithGoogleError: undefined,
  signInWithTwitter: () => {},
  signInWithTwitterUser: undefined,
  signInWithTwitterLoading: undefined,
  signInWithTwitterError: undefined,
  signOut: () => {},
  signOutLoading: undefined,
  signOutError: undefined,
  snackbarState: undefined,
  stripeBuy: () => {},
  stripeLoading: undefined,
  stripeManage: async () => {},
  updatedDevice: undefined,
  updatedEmail: undefined,
  updatedFeed: undefined,
  updatedName: undefined,
  updatedUsername: undefined,
  updateDevice: () => { },
  updateFeed: () => { },
  updateUsername: () => { },
});

const AuthContextProvider = (props) => {
  const [authValue, authLoading, authError] = useAuthState(auth);
  const [documentValue, documentLoading, documentError, documentSnapshot] = useDocumentData(getDocumentReferenceByAuth(authValue));
  const [signInWithFacebook, signInWithFacebookUser, signInWithFacebookLoading, signInWithFacebookError] = useSignInWithFacebook(auth);
  const [signInWithGoogle, signInWithGoogleUser, signInWithGoogleLoading, signInWithGoogleError] = useSignInWithGoogle(auth);
  const [signInWithTwitter, signInWithTwitterUser, signInWithTwitterLoading, signInWithTwitterError] = useSignInWithTwitter(auth);
  const [signOut, signOutLoading, signOutError] = useSignOut(auth);
  const [snackbarState, setSnackbarState] = useState({open: false, vertical: 'bottom', horizontal: 'center', message: '', severity: 'success'});
  const [stripeLoading, setStripeLoading] = useState(false);
  const [updatedDevice, setUpdatedDevice] = useState({value: '', error: {}, loading: false, changed: false});
  const [updatedEmail, setUpdatedEmail] = useState({value: '', error: {}, loading: false, changed: false});
  const [updatedFeed, setUpdatedFeed] = useState({value: '', error: {}, loading: false, changed: false});
  const [updatedName, setUpdatedName] = useState({value: '', error: {}, loading: false, changed: false});
  const [updatedUsername, setUpdatedUsername] = useState({value: '', error: {}, loading: false, changed: false});
  const [hideNavigation, setHideNavigation] = useState(false);

  useEffect(() => {
    if (documentValue) {
      setUpdatedDevice((prevState) => ({...prevState, value: documentValue.device}));
      setUpdatedEmail((prevState) => ({...prevState, value: documentValue.email}));
      setUpdatedFeed((prevState) => ({...prevState, value: documentValue.feed}));
      setUpdatedName((prevState) => ({...prevState, value: documentValue.name}));
      setUpdatedUsername((prevState) => ({...prevState, value: documentValue.username}));
    }
  }, [documentValue]);

  useEffect(() => {
    if (signInWithFacebookUser) {
      const {isNewUser} = getAdditionalUserInfo(signInWithFacebookUser);
      const {user} = signInWithFacebookUser;
      if (isNewUser) {
        createDocument(user.uid, {
          dates: [],
          device: '',
          email: user.email,
          feed: '',
          loads: 0,
          name: user.displayName,
          Pro: false,
          username: user.uid,
        });
      }
    }
  }, [signInWithFacebookUser]);

  useEffect(() => {
    if (signInWithGoogleUser) {
      const {isNewUser} = getAdditionalUserInfo(signInWithGoogleUser);
      const {user} = signInWithGoogleUser;
      if (isNewUser) {
        createDocument(user.uid, {
          dates: [],
          device: '',
          email: user.email,
          feed: '',
          loads: 0,
          name: user.displayName,
          Pro: false,
          username: user.uid,
        });
      }
    }
  }, [signInWithGoogleUser]);

  useEffect(() => {
    if (signInWithTwitterUser) {
      const {isNewUser} = getAdditionalUserInfo(signInWithTwitterUser);
      const {user} = signInWithTwitterUser;
      if (isNewUser) {
        createDocument(user.uid, {
          dates: [],
          device: '',
          email: user.email,
          feed: '',
          loads: 0,
          name: user.displayName,
          Pro: false,
          username: user.uid,
        });
      }
    }
  }, [signInWithTwitterUser]);

  const onSnackbarCloseHandler = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarState((prevState) => {
      return {...prevState, open: false};
    });
  };

  const onSnackbarOpenHandler = (additionalState) => {
    setSnackbarState((prevState) => {
      return {...prevState, ...additionalState, open: true};
    });
  };

  return (
    <AuthContext.Provider
      value={{
        auth: auth,
        authValue: authValue,
        authLoading: authLoading,
        authError: authError,
        documentValue: documentValue,
        documentLoading: documentLoading,
        documentError: documentError,
        documentSnapshot: documentSnapshot,
        hideNavigation: hideNavigation,
        onSnackbarClose: onSnackbarCloseHandler,
        onSnackbarOpen: onSnackbarOpenHandler,
        setHideNavigation: setHideNavigation,
        setStripeLoading: setStripeLoading,
        setUpdatedDevice: setUpdatedDevice,
        setUpdatedEmail: setUpdatedEmail,
        setUpdatedFeed: setUpdatedFeed,
        setUpdatedName: setUpdatedName,
        setUpdatedUsername: setUpdatedUsername,
        signInWithFacebook: signInWithFacebook,
        signInWithFacebookUser: signInWithFacebookUser,
        signInWithFacebookLoading: signInWithFacebookLoading,
        signInWithFacebookError: signInWithFacebookError,
        signInWithGoogle: signInWithGoogle,
        signInWithGoogleUser: signInWithGoogleUser,
        signInWithGoogleLoading: signInWithGoogleLoading,
        signInWithGoogleError: signInWithGoogleError,
        signInWithTwitter: signInWithTwitter,
        signInWithTwitterUser: signInWithTwitterUser,
        signInWithTwitterLoading: signInWithTwitterLoading,
        signInWithTwitterError: signInWithTwitterError,
        signOut: signOut,
        signOutLoading: signOutLoading,
        signOutError: signOutError,
        snackbarState: snackbarState,
        stripeBuy: buyPro,
        stripeLoading: stripeLoading,
        stripeManage: createPortalLink,
        updatedDevice: updatedDevice,
        updatedEmail: updatedEmail,
        updatedFeed: updatedFeed,
        updatedName: updatedName,
        updatedUsername: updatedUsername,
        updateDevice: updateDevice,
        updateFeed: updateFeed,
        updateUsername: updateUsername,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export {
  AuthContext,
  AuthContextProvider,
};
