// React
import React, {useContext} from 'react';
import {createBrowserRouter, defer, RouterProvider} from 'react-router-dom';

// Material UI
import {blueGrey, grey, yellow} from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {createBreakpoints} from '@mui/system';

// Hikers Social
import './App.scss';
import {MapContextProvider} from './context/map-context';
import {ModeContext} from './context/mode-context';
import {getDocumentByUsername, getDocumentIdByUsername} from './firebase/firestore';
import AccountPage from './pages/Account/Account';
import ErrorPage from './pages/Error/Error';
import HomePage from './pages/Home/Home';
import MapPage from './pages/Map/Map';
import NavigationPage from './pages/Navigation/Navigation';

const router = createBrowserRouter([
  {
    path: '/',
    element: <NavigationPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'account',
        element: <AccountPage />,
      },
      {
        path: 'map/:username',
        element: <MapContextProvider><MapPage /></MapContextProvider>,
        loader: async ({params}) => {
          return defer({
            uid: await getDocumentIdByUsername(params.username),
            doc: getDocumentByUsername(params.username),
          });
        },
      },
      {
        path: '*',
        element: <ErrorPage/>,
      },
    ],
  },
]);

const App = () => {
  const modeContext = useContext(ModeContext);
  const breakpoints = createBreakpoints({});

  const theme = React.useMemo(
      () => createTheme({
        components: {
          MUIContainer: {
            styleOverrides: {
              maxWidth: '1000px',
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                padding: '1rem',
                backgroundImage: 'none',
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                fontFamily: '\'Roboto\', sans-serif',
                lineHeight: '1rem',
                minWidth: '120px',
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                padding: 10,
                borderBottom: `1px solid ${modeContext.mode ? grey[700] : blueGrey[200]}`,
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              root: {
                '& .MuiPickersLayout-root': {
                  background: 'transparent',
                },
              },
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                color: 'inherit',
              },
            },
          },
        },
        palette: {
          mode: modeContext.mode ? 'dark' : 'light',
          primary: {main: modeContext.mode ? blueGrey[100] : blueGrey[900]},
          secondary: {main: yellow[900]},
          background: {
            default: modeContext.mode ? blueGrey[900] : blueGrey[100],
            paper: modeContext.mode ? grey[900] : grey[100],
          },
          text: {
            primary: modeContext.mode ? blueGrey[100] : blueGrey[900],
          },
          divider: modeContext.mode ? blueGrey[800] : blueGrey[200],
          footer: modeContext.mode ? blueGrey[800] : blueGrey[200],

          // Custom Colors (https://mui.com/material-ui/customization/palette/#customization)
          signOutButton: {
            main: modeContext.mode ? blueGrey[800] : blueGrey[200],
            contrastText: modeContext.mode ? grey[100] : grey[900],
          },
          orangeButton: {
            main: yellow[900],
            contrastText: '#ffffff',
          },
        },
        shape: {
          borderRadius: 2,
        },
        typography: {
          fontFamily: '\'Karla\', sans-serif',
          h1: {
            fontFamily: '\'Rubik\', sans-serif',
            fontWeight: 700,
            fontSize: '3rem',
            textTransform: 'upperCase',
            [breakpoints.down('sm')]: {
              fontSize: '2rem',
            },
          },
          h2: {
            fontFamily: '\'Rubik\', sans-serif',
            fontWeight: 700,
            fontSize: '2rem',
            textTransform: 'upperCase',
          },
          body1: {
            fontFamily: '\'Karla\', sans-serif',
          },
          pro: {
            fontFamily: '\'Karla\', sans-serif',
            color: '#333',
          },
        },
      }),
      [modeContext.mode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
