// React
import React from 'react';

// Material UI
import {Typography} from '@mui/material';
import {Container} from '@mui/system';

// Hikers Social
import Map from '../../assets/images/_Map.png';
import './Error.scss';

const ErrorPage = () => {
  return (
    <div className="error">
      <Container className="flexCenter">
        <Typography variant="h1" mt="40px">An error occurred!</Typography>
        <p>Could not find this page!</p>
        <img src={Map} />
      </Container>
    </div>
  );
};

export default ErrorPage;
