// React
import React, {useRef, useEffect, useContext} from 'react';

// Mapbox
import mapboxgl from '!mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1Ijoiam9zaGthdXR6IiwiYSI6ImNsYWgzOWt3OTA2eXYzcGtlZHF1M2tkZDAifQ.d29ODK6RydCd1XxmyjjysQ';

// Hikers Social
import addImages from '../../../../utils/mapbox/addImages';
// import addCurrentLocationLayer from '../../../../utils/mapbox/addCurrentLocationLayer';
import addLocationsLayer from '../../../../utils/mapbox/addLocationsLayer';
import addLocationsLineLayer from '../../../../utils/mapbox/addLocationsLineLayer';
import eventType from '../../../../utils/eventType';
import fitToCurrentBounds from '../../../../utils/mapbox/fitToCurrentBounds';
import mapImages from '../../../../utils/mapImages';
import mapStyles from '../../../../utils/mapStyles';
// import setSky from '../../../../utils/mapbox/setSky';
import setFog from '../../../../utils/mapbox/setFog';
// import setHillshade from '../../../../utils/mapbox/setHillshade';
import setTerrain from '../../../../utils/mapbox/setTerrain';
import waitForImages from '../../../../utils/mapbox/waitForImages';
import waitForStyle from '../../../../utils/mapbox/waitForStyle';
import {MapContext} from '../../../../context/map-context';
import './Map.css';
import getCurrentStyleName from '../../../../utils/mapbox/getCurrentStyleName';

const Map = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const context = useContext(MapContext);

  // Run once: On initial load.
  useEffect(() => {
    if (!mapboxgl.supported()) {
      alert('Your browser does not support Mapbox GL');
    }

    context.onMapLoad(map);

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyles.Satellite.url,
      center: [-93.31772374468406, 45.03869788883195],
      zoom: 5,
      pitch: 0,
    });

    map.current.addControl(new mapboxgl.ScaleControl({maxWidth: 80, unit: 'imperial'}), 'bottom-left');

    map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

    map.current.addControl(new mapboxgl.FullscreenControl({container: document.querySelector('body')}), 'bottom-left');

    map.current.on('styledataloading', () => {
      waitForStyle(map, () => {
        context.onMapStyleChange(getCurrentStyleName(map));
      });
    });

    waitForStyle(map, () => {
      addImages(map);
      setTerrain(map);
      setFog(map);
      // setHillshade(map);
      // setSky(map);
    });
  }, []);

  // Run multiple: When locations change.
  useEffect(() => {
    if (!context.collectionValues) {
      return;
    }

    const locations = (context.collectionValues) ?
      context.collectionValues
          .flatMap((document) => document.locations)
          .filter((location) => {
            const time = new Date(location.time.seconds * 1000);

            const startDate = new Date(context.dateRangeState.value[0]);
            startDate.setHours(0, 0, 0, 0);

            const endDate = new Date(context.dateRangeState.value[1]);
            endDate.setDate(context.dateRangeState.value[1].getDate() + 1);

            if (startDate <= time && time <= endDate) {
              return true;
            }
            return false;
          })
          .sort(function(a, b) {
            return new Date(a.time.seconds) - new Date(b.time.seconds);
          }) :
      [];

    waitForStyle(map, () => {
      addLocationsLineLayer(map, locations);
      addLocationsLayer(map, locations);
      // addCurrentLocationLayer(map, locations);
      fitToCurrentBounds(map, eventType.Automatic, locations);
    });
  }, [context.collectionValues]);

  // Run multiple: When style changes.
  useEffect(() => {
    if (!context.mapStyle) {
      return;
    }

    const locations = (context.collectionValues) ?
      context.collectionValues
          .flatMap((document) => document.locations)
          .filter((location) => {
            const time = new Date(location.time.seconds * 1000);

            const startDate = new Date(context.dateRangeState.value[0]);
            startDate.setHours(0, 0, 0, 0);

            const endDate = new Date(context.dateRangeState.value[1]);
            endDate.setDate(context.dateRangeState.value[1].getDate() + 1);

            if (startDate <= time && time <= endDate) {
              return true;
            }
            return false;
          })
          .sort(function(a, b) {
            return new Date(a.time.seconds) - new Date(b.time.seconds);
          }) :
      [];

    waitForStyle(map, () => {
      addImages(map);
      setTerrain(map);
      setFog(map);
      // setSky(map);
      waitForImages(map, [mapImages.Default, mapImages.Message], () => {
        addLocationsLineLayer(map, locations);
        addLocationsLayer(map, locations);
        // addCurrentLocationLayer(map, locations);
      });
    });
  }, [context.mapStyle]);

  return (
    <div ref={mapContainer} className="map-container" />
  );
};

export default Map;
