// React
import React, {useContext} from 'react';

// Material UI
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Hikers Social
import {AuthContext} from '../../../../context/auth-context';

const LinearProgressWithLabel = () => {
  const authContext = useContext(AuthContext);
  const dividend = authContext.documentValue.loads;
  const divisor = authContext.documentValue.Pro ? 1000 : 50;
  const value = 100 * dividend / divisor;

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={{minWidth: 75}}>
          <Typography variant="body2" color="text.secondary">{`Map loads:`}</Typography>
        </Box>
        <Box sx={{width: '100%', mr: 1}}>
          <LinearProgress variant="determinate" value={value} aria-label='Percentage of map loads used'/>
        </Box>
        <Box sx={{minWidth: 35}}>
          <Typography variant="body2" color="text.secondary">{`${dividend}/${divisor}`}</Typography>
        </Box>
        {/* TODO: Have "divisor" be bold + orange for users who are free, and a tooltip/info indicator that says they can upgrade for more loads. */}
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
