import mapboxgl from '!mapbox-gl';

import mapImages from '../mapImages';
import removeLayer from './removeLayer';
import removeSource from './removeSource';

const addLocationsLayer = (map, locations) => {
  removeLayer(map, 'Locations');
  removeSource(map, 'Locations');

  const features = locations.map((location) => (
    {
      type: 'Feature',
      properties: {
        id: location.id,
        time: location.time,
        message: location.message,
        icon: location.message ?
                    mapImages.Message :
                    mapImages.Default,
      },
      geometry: {
        type: 'Point',
        coordinates: [location.longitude, location.latitude],
      },
    }
  ));

  map.current.addSource('Locations', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features,
    },
  });

  map.current.addLayer({
    id: 'Locations',
    type: 'symbol',
    source: 'Locations',
    layout: {
      'icon-image': '{icon}',
      'icon-anchor': 'center',
      'icon-allow-overlap': true,
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        2,
        0.1,
        16,
        1,
        22,
        3,
      ],

      // "text-field": "{id}",
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-allow-overlap': true,
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        2,
        2,
        16,
        20,
        22,
        60,
      ],
      'text-offset': [0, -1.8],
      'text-anchor': 'center',
    },
    paint: {
      'icon-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        4,
        0,
        8,
        1,
        16,
        1,
        22,
        0,
      ],

      'text-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        4,
        0,
        8,
        1,
        16,
        1,
        22,
        0,
      ],
      'text-color': '#FFFFFF',
      'text-halo-color': 'rgba(0, 0, 0, 1)',
      'text-halo-width': 2,
    },
  });

  map.current.off('click', 'Locations');
  map.current.on('click', 'Locations', (e) => {
    const coordinates = e.features[0].geometry.coordinates.slice();
    const message = e.features[0].properties.message;
    const time = new Date(
        JSON.parse(e.features[0].properties.time).seconds * 1000,
    );

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // new mapboxgl.Popup({className: 'hsMapboxPopup'})
    new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(
                message ?
                    '<center class="hsMapboxPopup"><b>' +
                    time.toLocaleString() +
                    '</b><br />' +
                    message +
                    '</center>' :
                    '<center class="hsMapboxPopup"><b>' + time.toLocaleString() + '</b></center>',
        )
        .addTo(map.current);
  });

  map.current.off('mouseenter', 'Locations');
  map.current.on('mouseenter', 'Locations', (e) => {
    // Change the cursor style as a UI indicator.
    map.current.getCanvas().style.cursor = 'pointer';
  });

  map.current.off('mouseleave', 'Locations');
  map.current.on('mouseleave', 'Locations', () => {
    map.current.getCanvas().style.cursor = '';
  });
};

export default addLocationsLayer;
