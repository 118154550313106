// React
import React from 'react';

// Material
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Hikers Social
import Mappy from '../../../assets/images/_Mappy.png';
import './Hero.scss';

const HeroComponent = () => {
  return (
    <div className="hero">
      <Container>
        <div className="hero-title">
          <Typography variant="h1" textTransform={'uppercase'}>
            <span>Map Your Adventures With</span>
            <Box sx={{color: 'secondary.main'}}>Hikers Social <img align="right" src={Mappy} alt="" /></Box>
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default HeroComponent;
