import mapboxgl from '!mapbox-gl';

import eventType from '../eventType';
import flyToCurrentLocation from './flyToCurrentLocation';

const fitToCurrentBounds = (map, event, locations) => {
  const coordinates = locations.map((location) => [
    location.longitude,
    location.latitude,
  ]);

  if (coordinates.length === 0) {
    if (event === eventType.Automatic) {
      return;
    }
    if (event === eventType.Manual) {
      // enqueueSnackbar("No locations. Change date range.", { variant: "warning", });
      return;
    }
  }

  if (coordinates.length === 1) {
    flyToCurrentLocation(map, locations);
    return;
  }

  // Create a 'LngLatBounds' with both corners at the first coordinate.
  const bounds = new mapboxgl.LngLatBounds(
      coordinates[0],
      coordinates[0],
  );

  // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
  for (const coord of coordinates) {
    bounds.extend(coord);
  }

  map.current.fitBounds(bounds, {
    padding: 20,
  });
};

export default fitToCurrentBounds;
