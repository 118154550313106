// React
import React, {useCallback, useContext} from 'react';

// Material UI
import {Close as MUIClose} from '@mui/icons-material';
import {default as MUIButton} from '@mui/material/Button';
import {default as MUICircularProgress} from '@mui/material/CircularProgress';
import {default as MUIDialog} from '@mui/material/Dialog';
import {default as MUIDialogActions} from '@mui/material/DialogActions';
import {default as MUIDialogTitle} from '@mui/material/DialogTitle';
import {default as MUIIconButton} from '@mui/material/IconButton';
import {DateRangePickerDay as MUIDateRangePickerDay} from '@mui/x-date-pickers-pro/DateRangePickerDay';
import {StaticDateRangePicker as MUIStaticDateRangePicker} from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import {AdapterDateFns as MUIAdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider as MUILocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {pickersLayoutClasses} from '@mui/x-date-pickers/PickersLayout';
import {LicenseInfo as MUILicenseInfo} from '@mui/x-license-pro';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

// Hikers Social
import {MapContext} from '../../../../context/map-context';
import {useWindowDimensions} from '../../../../hooks/useWindowDimensions';
import {formatDate} from '../../../../utils/formatDate';
import './Dialog.scss';

MUILicenseInfo.setLicenseKey('63cdcff003c86a961f1b47b5703dd5e0Tz0wLEU9MjUzNDA0ODY0MDAwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sS1Y9Mg==');

const CustomActionBar = ({className}) => {
  const context = useContext(MapContext);

  const onClickHandler = useCallback(() => {
    context.onDateRangeSearch();
  }, []);

  return (
    <MUIDialogActions className={className}>
      <MUIButton onClick={onClickHandler} variant="contained" color="orangeButton">OK</MUIButton>
    </MUIDialogActions>
  );
};

const Dialog = () => {
  const context = useContext(MapContext);
  const {width} = useWindowDimensions();

  const hsDayHasData = useCallback((day) => {
    if (!context.documentValues) {
      return '';
    }
    if (context.documentValues.dates.some((date) => formatDate(new Date(date.seconds * 1000)) === formatDate(day))) {
      return 'hsDayHasData';
    }
    return '';
  }, [context.documentValues]);

  const CustomDay = useCallback(({day, ...dayProps}) => {
    return <MUIDateRangePickerDay day={day} {...dayProps} className={hsDayHasData(day)} />;
  }, [hsDayHasData]);

  return (
    <MUIDialog
      className='customDialog'
      onClose={context.onDateRangeClose}
      open={context.dateRangeState.open}
      keepMounted
    >
      <MUIDialogTitle>
        <Tooltip title="Close" placement="top" TransitionComponent={Zoom} arrow disableInteractive>
          <MUIIconButton onClick={context.onDateRangeClose} aria-label="Close">
            <MUIClose />
          </MUIIconButton>
        </Tooltip>
      </MUIDialogTitle>
      <MUILocalizationProvider dateAdapter={MUIAdapterDateFns}>
        <MUIStaticDateRangePicker
          disableHighlightToday={true}
          disabled={context.documentLoading || !context.documentValues}
          displayStaticWrapperAs={width < 800 ? 'mobile' : 'desktop'}
          loading={context.documentLoading || !context.documentValues}
          onChange={(dateRange) => {
            context.onDateRangeChange(dateRange);
          }}
          renderLoading={() => <MUICircularProgress />}
          slots={{
            actionBar: CustomActionBar,
            day: CustomDay,
          }}
          slotProps={{
            layout: {
              sx: {
                [`.${pickersLayoutClasses.contentWrapper}`]: {
                  gridColumn: 1,
                  gridRow: 1,
                },
                [`.${pickersLayoutClasses.actionBar}`]: {
                  gridColumn: 1,
                  gridRow: 2,
                },
              },
            },
            toolbar: {
              hidden: true,
            },
          }}
          value={context.dateRangeState.value}
        />
      </MUILocalizationProvider>
    </MUIDialog>
  );
};

export default Dialog;
