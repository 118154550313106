const mapStyles = {
  Satellite: {
    name: 'Mapbox Satellite Streets',
    url: 'mapbox://styles/mapbox/satellite-streets-v12',
  },
  Standard: {
    name: 'Mapbox Standard',
    url: 'mapbox://styles/mapbox/standard',
  },
};

export default mapStyles;
